require("../../../vendor/assets/libs/moment/moment")
require("../../../vendor/assets/libs/timepicker/timepicker")
require("../../../vendor/assets/libs/bootstrap-select/bootstrap-select")
require("../../../vendor/assets/libs/bootstrap-datepicker/bootstrap-datepicker")
require("../../../vendor/assets/libs/bootstrap-daterangepicker/bootstrap-daterangepicker")
require("../../../vendor/assets/libs/bootstrap-material-datetimepicker/bootstrap-material-datetimepicker")
require("../../../vendor/assets/libs/dropzone/dropzone")
require("../../../vendor/assets/libs/dragula/dragula")
require("../../../vendor/assets/libs/sortablejs/sortable")
require("../../../vendor/assets/libs/nestable/nestable")
require("../../../vendor/assets/libs/spin/spin")
require("../../../vendor/assets/libs/ladda/ladda")
require("../../../vendor/assets/libs/select2/select2")

require("jstree/dist/jstree")

import { Clipboard } from "../../../vendor/assets/libs/clipboard/clipboard";
import Sortable from 'sortablejs/Sortable'
import * as Ladda from 'ladda/js/ladda'

function drop(){
  $('.dropzone').each(function(){
    let dropzoneControl = $(this)[0].dropzone;
    if (dropzoneControl) { dropzoneControl.destroy(); }
    var files = null;
    var max_files = null;
    var multiple = false;
    if($(this).data("maxfiles")) { max_files = parseInt($(this).data("maxfiles")) }
    if($(this).data("files")) { files = $(this).data("files") }
    $(this).dropzone({
      url:             $(this).data("url"),
      acceptedFiles:   files,
      parallelUploads: 2,
      maxFiles:        max_files,
      maxFilesize:     50000,
      filesizeBase:    1000,
      addRemoveLinks:  true,
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      }
    });
  })
}

function tabs(){
  $('a[data-toggle="tab"]').on("shown.bs.tab", function(e){
    if ($(e.target).data("block-anchor")){}
    else {
      var anchor = $(e.target).attr("href");
      if (anchor) { window.location = anchor }
    }
  });

  if(window.location.hash)
  {
    var $elements = $("a.nav-link[href='"+window.location.hash+"']");
    if($elements.length){
      $elements.tab("show");
    }
  }
}

function sortables(){
  $("th.sortable").on("click", function(){
    var link = $(this).data("href");
    window.location = link;
  });

  if ($(".sortable-js").length > 0) {
    $(".sortable-js").each(function(){
      var sort = $(this);
      Sortable.create($(sort)[0], {
        handle: ".handle",
        onEnd: function (event) {
          var sort_url = $(event.to).data("sorturl");
          var sorted_data_ids = [];
          $(event.to).children(".sort-item").map(function () {
            sorted_data_ids.push($(this).data("id"))
          });
          $.post(sort_url, {"ids": sorted_data_ids}, function () {

          });
        }
      });
    });
  }

  if($(".nestable-container").length > 0){
    $.each($(".nestable-container"), function(index, container){
      $(container).nestable({ group: 1 }).on('change', function(e){
        var list   = e.length ? e : $(e.target);
        var data = list.nestable('serialize');
        $.post($(container).data("sort-url"), { data: { pages: data } }, function(r){
          console.log(r);
        });
      });
    });
  }
}

function ajaxforms(){
  Ladda.bind(".ladda-button", { timeout: 2000 });
  $("form[data-remote='true']").on("ajax:success", function(event){
    [status, xhr] = event.detail;
    console.log(event.detail);
    $(".layout-container").html($(xhr.responseText));
    fire();
  });

  $("form[data-remote='true']").on("ajax:error", function(event){
    [data, status, xhr] = event.detail;
    $(this).replaceWith($(xhr.responseText));
    fire();
  });

  $("a.delete-remote").on("ajax:success", function(event){
    var xhr = event.detail[2];
    $(this).parents("tr").remove();
  });

  $("a.remote-modal").on("ajax:success", function(event){
    var xhr = event.detail[2];
    $("#modals-default .modal-body").html($(xhr.responseText));
    $("#modals-default .modal-title").text($(this).attr("title"));
    if ($(this).data("size")) $("#modals-default").find(".modal-dialog").addClass($(this).data("size"));
    $("#modals-default").modal("show");
  });

  $("#modals-default").on("shown.bs.modal", function(e) {
    fire();
  });
}

function hints(){
  var hide_alert_timeout = window.setTimeout(function(){
    $("#flashbox .alert").slideUp(500);
    window.clearTimeout(hide_alert_timeout);
  }, 5000);
}

function showToast(type, title, msg){
  toastr[type](msg, title, {
    positionClass:     "toast-bottom-right",
    closeButton:       true,
    progressBar:       true,
    preventDuplicates: true,
    newestOnTop:       false,
    rtl:               $('body').attr('dir') === 'rtl' || $('html').attr('dir') === 'rtl'
  });
}

function pickers(){
  $('input.pick-datetime').bootstrapMaterialDatePicker({
    weekStart: 1,
    format : 'YYYY-MM-DD HH:mm:ss',
    shortTime: false,
    nowButton : true,
    clearButton: true
  });
  $('input.simple-timepicker').daterangepicker({
    autoUpdateInput: false,
    singleDatePicker: true,
    timePicker: true,
    timePickerIncrement: 1,
    timePicker24Hour: true,
    autoApply: true,
    drops: "down",
    locale: {
      format: 'DD/MM/YYYY HH:mm:ss'
    }
  });
  $("input.simple-timepicker").on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY HH:mm:ss'));
  });
  $('input.rangepicker').daterangepicker({
    autoUpdateInput: false,
    timePicker: true,
    timePickerIncrement: 1,
    timePicker24Hour: true,
    autoApply: true,
    drops: "down",
    locale: {
      format: 'DD/MM/YYYY HH:mm'
    }
  });
  $("input.rangepicker").on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY HH:mm') + ' - ' + picker.endDate.format('DD/MM/YYYY HH:mm'));
  });
  $('input.date-rangepicker').daterangepicker({
    autoUpdateInput: false,
    timePicker: false,
    autoApply: true,
    drops: "down",
    locale: {
      format: 'DD/MM/YYYY'
    }
  });
  $("input.date-rangepicker").on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
  });
  $('input.simple-datepicker').datepicker({
  });
}

function selects(){
  $('.select2').each(function() {
    $(this)
      .wrap('<div class="position-relative"></div>')
      .select2({
        allowClear: $(this).hasClass("optional"),
        placeholder: 'Select value',
        tags: $(this).hasClass("tags"),
        tokenSeparators: [',', ' '],
        dropdownParent: $(this).parent()
      });
  })
}

function modalTriggers(){
  $("a.modal-trigger").on("click", function(e){
    e.preventDefault();
    var target = $($(this).data("target"));
    $(target).find(".modal-dialog").addClass($(this).data("size"));
    $(target).find(".modal-title").html($(this).data("title"));
    $.get($(this).attr("href"), function (e) {
      $(target).find(".modal-body").html(e);
      target.modal("show");
    });
  });
}

function checkAllBoxes(){
  $("input.check-all").on("change", function(e){
    $($(this).attr("rel")).prop("checked", $(this).prop("checked") );
  });
}

function tree(){
  $(".jstree").each(function(){
    $(this).jstree().on("select_node.jstree", function(e, data){
      window.location = data.event.target.href;
    });
  });

  $(".btn-show-more").on("click", function(e){
    var rel = $(this).attr("rel");
    $(rel).toggleClass("hidden");
    $(this).children(".text").toggleClass("hidden");
    $(this).toggleClass("btn-default btn-primary").blur();
  });
}

function clip(){
  if (Clipboard.isSupported()) {
    new Clipboard(".clipboard");
  } else {
    $(".clipboard").prop('disabled', true);
  }
}

function ajaxSwitchers(){
  $(".ajax-switcher-form .switcher-input[type=checkbox]").on("change", function(){
    let form = $(this).parents(".ajax-switcher-form");

    if(form){
      $.ajax({
        type: 'PATCH',
        url: form.attr("action"),
        data: form.serialize(),
        success: function(response) {
          console.log(response);
        },
        error: function(){
          console.log("error");
          $(this).checked = !$(this).checked;
        },
      });
    }
  });
};

function fire(){
  hints();
  tabs();
  drop();
  sortables();
  ajaxforms();
  pickers();
  selects();
  modalTriggers();
  checkAllBoxes();
  tree();
  ajaxSwitchers();
  clip();
}

$(document).on('turbolinks:load', function(){
  fire();
});
